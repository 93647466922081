.eight-bit-color-picker {
  max-width: 34px;
}

.eight-bit-color-picker .ebcp-selection, .ebcp-color-preview {
  width: 100%;
  height: 50%;
}

.ebcp-selector {
  -webkit-user-select: none;
  user-select: none;
  width: 15.9659vw;
  height: 17.6155vh;
  opacity: 0;
  visibility: hidden;
  background-color: #444;
  padding: 20px;
  transition: all .2s ease-in-out;
  position: absolute;
  overflow: hidden;
  box-shadow: 3px 3px 4px #0006;
}

.ebcp-shown-selector {
  opacity: 1;
  visibility: visible;
}

.ebcp-selector .ebcp-palette, .ebcp-selector .ebcp-preview-values {
  display: inline-block;
}

.ebcp-palette {
  max-width: 164px;
}

.ebcp-selector .ebcp-preview-values {
  margin-left: 20px;
  position: relative;
  bottom: 35%;
}

.ebcp-palette-row {
  display: inline-block;
}

.ebcp-palette-row > div {
  width: 10px;
  height: 10px;
  cursor: crosshair;
}

.ebcp-color-preview {
  position: relative;
  top: -20px;
}

.ebcp-text-container {
  display: none;
}

.ebcp-color-preview {
  width: 2vw;
  height: 2vw;
  border-radius: 10px;
  display: inline-block;
}

.ebcp-text-container {
  width: 80px;
}

.ebcp-text {
  width: 70px;
  font-family: Menlo, monospace;
  font-size: 16px;
  font-weight: 300;
  display: block;
}

@media screen and (width <= 1400px) {
  .ebcp-selector {
    width: 50%;
    height: 70%;
  }
}

@media screen and (width <= 768px) {
  .ebcp-selector {
    height: 100%;
    overflow: auto;
  }

  .ebcp-color-preview {
    display: none;
  }
}

/*# sourceMappingURL=index.6fb2f274.css.map */
