.eight-bit-color-picker {
  max-width: 34px;
}
.eight-bit-color-picker .ebcp-selection,
.ebcp-color-preview {
  width: 100%;
  height: 50%;
}
.ebcp-selector {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: absolute;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
  padding: 20px;
  width: 15.965939329430547vw;
  height: 17.61546723952739vh;
  background-color: #444;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.ebcp-shown-selector {
  opacity: 1;
  visibility: visible;
}
.ebcp-selector .ebcp-palette,
.ebcp-selector .ebcp-preview-values {
  display: inline-block;
}
.ebcp-palette {
  max-width: 164px;
}
.ebcp-selector .ebcp-preview-values {
  position: relative;
  bottom: 35%;
  margin-left: 20px;
}
.ebcp-palette-row {
  display: inline-block;
}
.ebcp-palette-row > div {
  width: 10px;
  height: 10px;
  cursor: crosshair;
}
.ebcp-color-preview {
  position: relative;
  top: -20px;
}
.ebcp-text-container {
  display: none;
}
.ebcp-color-preview {
  display: inline-block;
  width: 2vw;
  height: 2vw;
  border-radius: 10px;
}
.ebcp-text-container {
  width: 80px;
}
.ebcp-text {
  display: block;
  font-family: Menlo, monospace;
  font-weight: 300;
  font-size: 16px;
  width: 70px;
}

@media screen and (max-width:1400px) {
  .ebcp-selector{
    width: 50%;
    height: 70%;
  }
}

@media screen and (max-width: 768px) {
  .ebcp-selector {
    overflow: auto;
    height: 100%;
  }
  .ebcp-color-preview {
    display: none;
  }
}
